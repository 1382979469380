import React, { useEffect } from 'react'
import { useToast } from '../../hooks/useToast';
import config from '../../../../config/serverless-config.json';
import { useTranslation } from 'react-i18next';


export default function AppVersionChecker() {
    const { toast } = useToast();
    //@ts-ignore
    const { t } = useTranslation('toasts');

    function doUpdate(e: React.MouseEvent<HTMLDivElement>) {
        try {
            navigator.serviceWorker
                .getRegistrations()
                .then((registrations) => Promise.all(registrations.map((r) => r.unregister()))
                )
                .then(() => (window.location.reload as any)(true));
        } catch {
            (window.location.reload as any)(true);
        }

    }

    async function fetchVersion() {
        try {
            const currentVersion = config.appVersion;
            const res = await fetch(`/version.txt?buster=${Date.now()}`);
            const version = await res.text();

            if (currentVersion !== version) {
                toast({
                    message: <div style={{ cursor: 'pointer', textDecoration: 'underlined' }} onClick={doUpdate}>{t('app.newVersion')}</div>,
                    type: 'info',
                    delay: 10 * 1000 //10 seconds to give time to read
                });
            }

        } catch {
        }
    }

    useEffect(() => {
        /**Check every 10 minutes */
        const interval = setInterval(fetchVersion, 60 * 10 * 1000);
        /**Call at app load */
        fetchVersion();

        return () => {
            if (interval)
                clearInterval(interval);
        }
    }, []);



    return null
}