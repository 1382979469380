import { PortfolioModel } from "../../../models/PortfolioModel"
import apiService from "../../common/services/api.service"
import config from '../../../config/serverless-config.json';
import { ListResult } from "../../common/types/ListResult";
import { PortfolioSearchModel } from "../../../models/PortfolioSearchModel";
import { CastingToolSearchResultItemDTO, WorkExperienceDTO } from "shared-module";
import { ISignUpResult } from "amazon-cognito-identity-js";
import { ContactRequestDTO, RegisterDTO } from "shared-module/src/types";
import { convertLegacyCitytoGmap } from "../../../utils/locationUtils";


export class PortfolioService {
    getOne = async (userId: string) => {
        const res = await apiService.get<PortfolioModel>(`${config.stage}/users/${userId}/portfolio`);
        this._adaptData(res.data);
        return res.data!;
    }

    getByUsername = async (username: string) => {
        const res = await apiService.get<PortfolioModel>(`${config.stage}/users/${username}/portfolio?username=1`);
        this._adaptData(res.data);
        return res.data;
    }

    getFollowedAccounts = async (cursor?: string) => {
        const res = await apiService.get<ListResult<CastingToolSearchResultItemDTO>>(`${config.stage}/portfolios/followed`, { params: { after: cursor } })
        return res.data;
    }

    upsert = (data: { userId: string, entity: Partial<PortfolioModel> }): Promise<boolean> => {
        return apiService
            .post<boolean>(`${config.stage}/users/${data.userId}/portfolio`, data.entity)
            .then(x => x.status >= 200 && x.status < 300);
    }

    setPreferedLanguage = async (language: string) => {
        await apiService.post(`${config.stage}/users/set-language`, { language });
    }

    upsertWorkExperience(userId: string, workExperience: WorkExperienceDTO) {
        return apiService
            .post<boolean>(`${config.stage}/users/${userId}/portfolio/work-experience`, workExperience)
            .then(x => x.status >= 200 && x.status < 300);
    }

    deleteWorkExperience(userId: string, workExperienceId: string) {
        return apiService
            .delete<boolean>(`${config.stage}/users/${userId}/portfolio/work-experience/${workExperienceId}`)
            .then(x => x.status >= 200 && x.status < 300);
    }


    list = (cursor?: string) => {
        return apiService.get<ListResult<CastingToolSearchResultItemDTO>>(`${config.stage}/portfolios`, { params: { after: cursor } }).then(x => x.data);
    }

    search = async (filters: PortfolioSearchModel, skip?: number) => {
        filters.skip = skip;
        const x = await apiService.post<ListResult<CastingToolSearchResultItemDTO>>(`${config.stage}/search/portfolios`, filters);
        return x.data;
    }

    onAccountCreation = async (result: ISignUpResult, data: RegisterDTO) => {
        const res = await apiService.post<PortfolioModel>(`${config.stage}/users/${result.userSub}/registered`, data);
        return res.data;
    }

    sendContactRequest = async (payload: ContactRequestDTO): Promise<void> => {
        await apiService.post(`${config.stage}/email/contact`, payload);
    }

    toggleFollow = async (portfolioId: string) => {
        const result = await apiService.post<{ following: boolean }>(`${config.stage}/portfolios/${portfolioId}/toggle-follow`);
        return result.data;
    }


    private _adaptData(data: PortfolioModel) {
        if (data.locations && data.locations.length) {
            data.locations = data.locations.map(convertLegacyCitytoGmap);
        }
    }

}