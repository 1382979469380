import axios, { Axios, AxiosError, AxiosRequestConfig } from "axios";
import appConfig from '../../../config/serverless-config.json';
import cognitoService from "../../auth/services/cognito.service";
import { PaywalModalService } from "../../subscription/services/paywallModalService";
import { SubscriptionPlanName } from 'shared-module';

const instance = axios.create({
    baseURL: appConfig.apiGatewayBaseUrl,
    headers: {
        "Content-Type": "application/json",
    },
});

instance.interceptors.request.use(
    async (config) => {
        if (config.baseURL !== appConfig.cognitoLoginUrl) {
            if (!config.headers)
                config.headers = {};

            config.headers['x-api-key'] = appConfig.apiKey

            try {
                const session = await cognitoService.instance.getSession();

                if (session) {
                    config.headers["Authorization"] = 'Bearer ' + session.getIdToken().getJwtToken();
                }
            } catch (error: any) {
                if (error.response.code === 401 && error.response.data.message === "The incoming token has expired") {
                    console.log('Token expired');
                    return;
                }
            }

        }
        return config;
    },
    (error) => {
        return Promise.reject();
    }
);

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;

        if (originalConfig && originalConfig.baseUrl !== appConfig.cognitoLoginUrl) {

            const expired = err.response.status === 401 && err.response.data.message === "The incoming token has expired";
            if (expired) {
                return await manageTokenExpiration(originalConfig);
            } else if (err.response.status === 401 && err.response.data.name === "PaywallException") {
                PaywalModalService.showPaywall(err.response.data);
            }

        }

        return Promise.reject(err);
    }
);

async function manageTokenExpiration(originalConfig: AxiosRequestConfig & { _retry?: boolean }) {
    if (originalConfig._retry) {
        //Refresh token is also expired
    } else {
        // Id Token is expired
        originalConfig._retry = true;

        try {
            await cognitoService.instance.getSession()

            return instance(originalConfig);
        } catch (_error) {
            return Promise.reject(_error);
        }

    }
}


const service = {
    get: instance.get,
    post: instance.post,
    put: instance.put,
    patch: instance.patch,
    delete: instance.delete,
};

export default service;