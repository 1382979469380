import { createContext } from "react";
import { UserModel } from "../../../models/UserModel";
import { getPromiseMock } from "../../../utils/getPromiseMock";
import { SubscriptionPlanName } from 'shared-module';

interface AuthContextType {
    user?: UserModel,
    jwt?: string;
    isLoading: boolean;
    logout: () => void;
    login: (email: string, password: string) => Promise<void>
    refetchUser: () => Promise<void>
}

const authContext = createContext<AuthContextType>({
    isLoading: false,
    logout: () => { },
    login: (email, password) => new Promise<void>((r) => { r() }),
    refetchUser: () => getPromiseMock(),
});

export default authContext;