import React, { createContext, PropsWithChildren, useContext, useReducer } from 'react';

export type ToastModel = {
    id: string,
    type: 'success' | 'danger' | 'info' | 'warning',
    delay?: number
} & (
        {
            title: string | JSX.Element
            message?: string | JSX.Element
        } | {
            title?: string | JSX.Element
            message: string | JSX.Element
        }
    );

export type ToastAction = {
    type: 'DELETE_TOAST',
    id: ToastModel['id']
} | {
    type: 'ADD_TOAST',
    toast: ToastModel
}

const initialValue = { toasts: [] as ToastModel[] };
const ToastStateContext = createContext(initialValue);
const ToastDispatchContext = createContext<React.Dispatch<ToastAction> | null>(null);

const ToastReducer: React.Reducer<typeof initialValue, ToastAction> = (state: typeof initialValue, action: ToastAction) => {
    switch (action.type) {
        case 'ADD_TOAST': {
            return {
                ...state,
                toasts: [...state.toasts, action.toast],
            };
        }
        case 'DELETE_TOAST': {
            const updatedToasts = state.toasts.filter((e) => e.id !== action.id);
            return {
                ...state,
                toasts: updatedToasts,
            };
        }
        default: {
            throw new Error('unhandled action type');
        }
    }
}

export function ToastProvider({ children }: PropsWithChildren<unknown>) {
    const [state, dispatch] = useReducer(ToastReducer, { toasts: [] }, (e) => { return { ...initialValue } });

    return (
        <ToastStateContext.Provider value={state}>
            <ToastDispatchContext.Provider value={dispatch}>
                {children}
            </ToastDispatchContext.Provider>
        </ToastStateContext.Provider>
    );
}

export const useToastDispatchContext = () => useContext(ToastDispatchContext);
export const useToastStateContext = () => useContext(ToastStateContext);